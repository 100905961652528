@import url('https://fonts.googleapis.com/css2?family=Khand:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300&display=swap');

* {
  /* font-family: 'Inter', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  /* font-weight: 100; */
  /* font-family: 'Teko', sans-serif; */
  /* font-family: 'Bebas Neue', sans-serif; */
}
.price{
  font-size: 12.5px;
}
.price1{
  font-size: 10px;
}
.text-spacing {
  line-height: 1  !important;
  margin-bottom: 0px;
}
.plur{
  opacity: 100%;
  background: #00000050 !important;
  z-index:1 ;
}

.k {
  font-family: "Great Vibes", cursive;
}

.logo {

  font-family: 'Bebas Neue', sans-serif;
  /* font-weight: normal; */
  text-shadow: 0 1px 1px #fff;
}

.lineb {
  width: 20%;
  margin-left: 2px;
  margin-right: 2px;
  height: 2px;
  background-color: black;
}

.categ {
  transition: 400ms;
}

.categ:hover {
  transform: scale(1.1);
}

.shopnow {
  bottom: 10% !important;
  left: 15% !important;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* product card */
.col-xs-12 {
  width: 100%;
}

.product-img {
  max-height: 250px;
}

.img-div {
  min-height: 200px;
}

.pink-text {
  color: #EDA7B1 !important;
}

.out {
  background-color: #0000004e;


}

.bg-pink {
  background-color: #EDA7B1;
}

.brand {
  font-size: 12px;
}

.card-bg {
  background-color: #f5f5f5;
}
.ordercart{
  height: 500px;
}
.product-btn {
  transition: 700ms !important;
  background-color: white !important;
}

.logout-btn {
  transition: 700ms !important;
  background-color: #EDA7B1 !important;
}
.logout-btn:hover{
  background-color: white;
}
.logout-btn:hover .white-text{
  background-color: #EDA7B1;
}
.white-text{
  color: whitesmoke;
}
.dlt {
  z-index: 2;
}

.navv {
  z-index: 5;
}

.text-limit {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.rate{
  position: relative;
  z-index: 2;
}
.product-btn:hover {
  transition: 700ms !important;
  background: #EDA7B1 !important;
}

.product-btn:hover .pink-text {
  color: whitesmoke !important;
}

.icon {
  z-index: 4;
  transition: 500ms;
}

.icon:hover {
  transform: scale(1.1);
  color: #EDA7B1;
}

.make-cursor {
  cursor: pointer;
}

/* nav */
.fit-width {
  width: fit-content !important;
}
ul li {
  cursor: pointer;
}

.atleast-50w {
  min-width: 50%;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: rgb(0 0 0 / 0%) !important;
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}

.popup-content {
  margin: auto;
  background: transparent !important;
  width: 50% !important;
  border: 0px !important;

}

.dropdown-menu {
  height: 200px;
  overflow-y: scroll;
}

.carousel-inner {

  height: 100%;
}

.caro {
  height: 70%;
  max-height: 600px;
  min-height: 250px;
}

.mx-hi {
  overflow-y: auto;

}

.carousel-indicators {
  display: none !important;
}

.line {
  width: 10%;
  margin-left: 2px;
  margin-right: 2px;
  height: 2px;
  background-color: white;
}

.circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: whitesmoke;
}

.loading {
  height: 100%;
  min-height: 600px;
}
.foot{
  background: #EDA7B1;
}
.crt{
  min-height: 90vh;
  height: fit-content;
}
.form-control:focus {
  box-shadow: 0 0 0 0.25rem #eda7b14e !important;
}
.ready{
color: 	#FF8C00
;}
@media screen and (max-width: 576px) {
  .ordercart{
    height: 400px;
  }
  .shopnow {
    bottom: 2% !important;
    left: 2% !important;
  }
  .col-xs-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xs-5 {
    flex: 0 0 auto !important;
    width: 45% !important;
}
  .lineb {
    width: 10%;
  }

  .img-div {
    height: 170px;
  }

  .col-xs-12{
    flex: 0 0 auto !important;
    width: 100% !important;
  }
  .caro {
    height: 35%;
    max-height: 600px;
    min-height: 250px;
  }

  .fs-xs-6 {
    font-size: 14px !important;
  }

  .p-4 {
    padding: 0.5rem !important;
  }
}
@media screen and (min-width:768px) and (max-width:800px) {


  .img-div {
    height: 150px;
    width: 150px;
  }

}
@media screen and (min-width:800px) {


  .img-div {
    height: 150px;
    width: 150px;
  }

}
@media screen and (max-width:1200px) {
  .product-img {
    max-height: 250px;
  }

  .img-div {

    min-height: 200px;
  }

}